import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { ExceptionHandler } from 'src/app/ajs-upgraded-providers';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';
import { ApiService } from './api.service';
import { ApiResponse } from '../api';

@Injectable({
  providedIn: 'root'
})
export class UserauthApiService extends ApiService<any> {

  constructor(
    protected httpClient: HttpClient,
    protected companyStateService: CompanyStateService,
    private exceptionHandler: ExceptionHandler
  ) {
    super(httpClient, companyStateService);
  }

  override getResourceUrl(): string {
    return '/v2/userauth';
  }

  add(username: string, password: string, hash?: string): Promise<ApiResponse<string>> {
    const body = {
      username: username,
      password: password,
      joinHash: hash || ''
    };

    return firstValueFrom(super.addAsObservable(body));
  }

  updatePassword(username: string, oldPassword: string, newPassword: string): Promise<ApiResponse<string>> {
    const params = new HttpParams()
      .set('username', username);
    const body = {
      oldPassword: oldPassword,
      newPassword: newPassword
    };

    return firstValueFrom(super.requestAsObservable(null, 'PUT', 'update-password', params, body));
  }

  login(username: string, password: string, userConfirmedToken?: string): Promise<ApiResponse<string>> {
    const params = new HttpParams()
      .set('username', username);
    const body = {
      password: password,
      userConfirmedToken: userConfirmedToken
    };

    return firstValueFrom(super.requestAsObservable(null, 'POST', 'login', params, body));
  }

  refreshToken(token: string): Promise<ApiResponse<string>> {
    const body = {
      token: token
    };

    return firstValueFrom(super.requestAsObservable(null, 'POST', 'refresh-token', null, body));
  }

  requestConfirmationEmail(username: string): Promise<ApiResponse<string>> {
    const params = new HttpParams()
      .set('username', username);

    return firstValueFrom(super.requestAsObservable(null, 'PUT', 'request-confirmation-email', params))
    .catch(e => {
      this.exceptionHandler(e, 'Failed to request confirmation email.', true);
      throw e;
    });
  }

  requestPasswordReset(username: string): Promise<ApiResponse<string>> {
    const params = new HttpParams()
      .set('username', username);

    return firstValueFrom(super.requestAsObservable(null, 'PUT', 'request-password-reset', params));
  }

  resetPassword(username: string, passwordResetToken: string, newPassword: string): Promise<ApiResponse<string>> {
    const params = new HttpParams()
      .set('username', username);
    const body = {
      passwordResetToken: passwordResetToken,
      newPassword: newPassword
    };

    return firstValueFrom(super.requestAsObservable(null, 'PUT', 'reset-password', params, body));
  }

  canProceedWithSSO(username?: string, companyId?: string): Promise<ApiResponse<boolean>> {
    let params = new HttpParams();

    if (username) {
      params = params
        .set('username', username);
    }

    if (companyId) {
      params = params
        .set('companyId', companyId);
    }

    return firstValueFrom(super.requestAsObservable(null, 'GET', 'sso-can-proceed', params));
  }

}
